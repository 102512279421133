import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FiCalendar } from 'react-icons/fi';
import {
  Container,
  Content,
  Schedule,
  Section,
  InputGroup,
  ValidadeGroup,
  BlockGroup,
  VinculoInput,
  RowContainer,
  Row,
  ResetGroup,
} from './styles';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useToast } from '../../hooks/toast';
// import getValidationErrors from '../../utils/getValidationErrors';
import Input from '../../components/Input';
import InputDate from '../../components/InputDate';
import Button from '../../components/Button';
import Select from '../../components/Select';
import InputMask from '../../components/InputMask';

import { calcularIdade } from '../../utils/calcular';
import api from '../../services/api';
import { convertToDate } from '../../utils/converter';
import Option from '../../components/Option';
import { RadioOption } from '../../model/Inputs';
import { escolaridade, estados } from './apoio';
import Checkbox from '../../components/Checkbox/V2';
import Modal from '../../components/Modal';
import { useAuth } from '../../hooks/auth';

const radioOptions: RadioOption[] = [
  { id: '9', value: 'Aluno', label: 'Aluno' },
  { id: '8', value: 'SuperAluno', label: 'Aluno Super' },
];

const CadastroAluno: React.FC = () => {
  const { id } = useParams();
  const { role } = useAuth();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [aluno, setAluno] = useState<any>(null);
  const [professor, setProfessor] = useState(0);
  const [edit, setEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [grupos, setGrupos] = useState([]);
  const [vinculos, setVinculos] = useState<any>([]);
  const [count, setCount] = useState(0);
  const [questionarios, setQuestionarios] = useState([]);
  const [professores, setProfessores] = useState<any>([]);
  const [excluir, setExcluir] = useState(false);
  // const [questionarioOk, setQuestionarioOk] = useState(false);
  const [confirmExcluir, setConfirmExcluir] = useState(false);

  useEffect(() => {
    api
      .get('grupos/all')
      .then((response) => {
        setGrupos(response.data);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    api
      .get('professores/all')
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = `${r.nome} ${r.sobrenome}`.trim())));
        setProfessores(response.data);
      })
      .then(() => {})
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      });
  }, [addToast]);

  useEffect(() => {
    api
      .get('professores/all')
      .then((response) => {
        // eslint-disable-next-line
        response.data.map((r: any) => ((r.value = r.id), (r.name = `${r.nome} ${r.sobrenome}`.trim())));
        setProfessores(response.data);
      })
      .then(() => {
        if (Number(id) > 0) {
          setEdit(true);
          api.get(`alunos/${id}`).then((response) => {
            setAluno(response.data);
            setProfessor(response.data.professor);
            formRef.current?.setData(response.data);
            formRef.current?.setFieldValue('estadocivil', response.data.estadocivil);
            formRef.current?.setFieldValue('escolaridade', response.data.escolaridade);
            formRef.current?.setFieldValue('professor', response.data.professor);
          });
          getVinculos();
        }
        getQuestionarios();
        // eslint-disable-next-line
      })
      .then(() => {
        if (aluno) {
          formRef.current?.setFieldValue('role', aluno.role);
          formRef.current?.setFieldValue('estadocivil', aluno.estadoCivil);
          formRef.current?.setFieldValue('escolaridade', aluno.escolaridade);
          formRef.current?.setFieldValue('professor', aluno.professor);
          // console.log(aluno);
        }
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: error.message,
        });
      })
      .finally(() => setShowForm(true));
  }, [id, count]);

  useEffect(() => {
    if (aluno) {
      formRef.current?.setFieldValue('role', aluno.role);
      formRef.current?.setFieldValue('estadocivil', aluno.estadoCivil);
      formRef.current?.setFieldValue('escolaridade', aluno.escolaridade);
      formRef.current?.setFieldValue('professor', aluno.professor);
      // console.log(aluno);
    }
  }, [aluno]);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatorio'),
          sobrenome: Yup.string().required('Sobrenome obrigatorio'),
          nascimento: Yup.string().required('Nascimento obrigatorio'),
          email: Yup.string().required('Email obrigatorio'),
          sexo: Yup.string().required('Sexo obrigatorio'),
          celular: Yup.string().required('Celular obrigatorio'),
          cpf: Yup.string().required('CPF obrigatorio'),
          grupo: Yup.string().notRequired(),
          professor: Yup.string().notRequired(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        if (data.grupo === '') {
          data.grupo = 0;
        }
        // console.log(!data.hasOwnProperty('professor') || data.professor === '' || data.professor === 0);
        // console.log(aluno);
        if (!data.hasOwnProperty('professor') || data.professor === '' || data.professor === '0') {
          data.professor = 0;
          if (aluno) {
            data.professor = aluno.professor;
          }
        }

        data.nascimento = convertToDate(data.nascimento);
        data.estado = data.estado === '' ? null : data.estado;
        data.estadoCivil = data.estadoCivil === '' ? null : data.estadoCivil;
        data.escolaridade = data.escolaridade === '' ? null : data.escolaridade;
        if (data.questionarios && edit) {
          data.questionarios = data.questionarios.filter((o: any) => o.checked).map((q: any) => q.id);
        }
        // if (questionarioOk) {
        //   data.questionarios = [];
        // }
        // const a = { ...data, nascimento: convertToDate(data.nascimento) };
        if (edit) {
          await api.put('alunos/' + id, data);

          addToast({
            type: 'success',
            title: 'Cadastro atualizado',
          });
          setLoading(false);
        } else {
          const response = await api.post('alunos', data);
          addToast({
            type: 'success',
            title: 'Cadastro realizado',
          });

          setLoading(false);
          formRef.current?.reset();
          navigate(`/cliente/${response.data.id}/questionarios`);
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err);
        // const errors = getValidationErrors(err);
        // formRef.current?.setErrors(errors);
        let errMsg = err.response.data?.message;
        addToast({
          type: 'error',
          title: errMsg || 'Erro ao gravar cadastro',
        });
      }
    },
    // eslint-disable-next-line
    [addToast, navigate, edit, id]
  );

  const handleCalcIdade = (valor: Date) => {
    const idade = calcularIdade(valor, new Date());
    formRef.current?.setFieldValue('idade', idade);
  };

  const changeAcesso = useCallback(async () => {
    const role = formRef.current?.getFieldValue('role');
    try {
      await api.post(`account/changerole/${id}?role=${role}`);
      addToast({
        type: 'success',
        title: 'Acesso atualizado',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao atualizar',
      });
    }
  }, [id, addToast]);

  const bloquear = useCallback(async () => {
    try {
      await api.post(`alunos/lock/${id}`);
      addToast({
        type: 'success',
        title: 'Bloqueado',
      });
      setCount(count + 1);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao bloquear',
      });
    }
  }, [id, addToast, setCount, count]);

  const desbloquear = useCallback(async () => {
    const validade = formRef.current?.getFieldValue('validade');
    const dia = validade.split('/')[0];
    const mes = validade.split('/')[1] - 1;
    const ano = validade.split('/')[2];
    const newValidade = new Date(ano, mes, dia);
    // const data = formRef.current?.getData();
    // if (data == undefined) return;
    // if (data.questionarios && edit) {
    //   data.questionarios = data.questionarios.filter((o: any) => o.checked).map((q: any) => q.id);
    // }

    try {
      await api.post(`alunos/unlock/${id}?validade=${newValidade.toISOString()}`);
      addToast({
        type: 'success',
        title: 'Desbloqueado',
      });
      setCount(count + 1);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Erro ao desbloquear',
      });
    }
    setModal(false);
  }, [id, addToast, setCount, count]);

  const getCEP = () => {
    const _cep: string = formRef.current?.getFieldValue('cep') || aluno.cep;
    const cep = _cep.replaceAll('_', '');
    // console.log(_cep.replaceAll('_', ''));

    if (cep.length < 9) return;
    api
      .get(`https://viacep.com.br/ws/${cep}/json/`)
      .then((response) => {
        if (response.data.erro) {
          addToast({
            type: 'error',
            title: 'Ocorreu um error ao buscar o CEP',
          });
          return;
        }

        const { uf, logradouro, localidade } = response.data;
        const estado = estados.find((e) => e.name === uf);

        formRef.current?.setFieldValue('endereco', logradouro);
        formRef.current?.setFieldValue('estado', estado?.value);
        formRef.current?.setFieldValue('cidade', localidade);
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: 'Ocorreu um error ao buscar o CEP',
        });
      });
  };

  const getQuestionarios = () => {
    try {
      api.get(`questionario`).then((resp) => {
        setQuestionarios(resp.data);
        if (id) {
          getQuestionariosAluno();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionariosAluno = () => {
    try {
      api.get(`questionario/aluno/${id}`).then((resp) => {
        const data = resp.data;
        // console.log(data);
        if (data.parq || data.anamnese || data.radar || data.neuroQ || data.imc) {
          // setQuestionarioOk(true);
        }
        if (data.objetivo && !data?.objetivoPreenchimento) {
          formRef.current?.setFieldValue(`questionarios[0].checked`, true);
        }
        if (data.parq && !data?.parqPreenchimento) {
          formRef.current?.setFieldValue(`questionarios[1].checked`, true);
        }
        if (data.anamnese && !data?.anamnesePreenchimento) {
          formRef.current?.setFieldValue(`questionarios[2].checked`, true);
        }
        if (data.radar && !data?.radarPreenchimento) {
          formRef.current?.setFieldValue(`questionarios[3].checked`, true);
        }
        if (data.neuroQ && !data?.neuroQPreenchimento) {
          formRef.current?.setFieldValue(`questionarios[4].checked`, true);
        }
        if (data.imc && !data?.imcPreenchimento) {
          formRef.current?.setFieldValue(`questionarios[5].checked`, true);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  // eslint-disable-next-line
  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = (): void => {
    setModal(false);
  };

  const Vincular = () => {
    const grupo = formRef.current?.getFieldValue('grupoaluno');
    const aluno = id;
    const data = {
      grupo: grupo,
      aluno: aluno,
    };

    try {
      api.post('grupos/vincular', data).then((resp) => {
        // console.log(resp.data);
        addToast({
          type: 'success',
          title: 'Vinculado com sucesso',
        });
        getVinculos();
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao vincular o aluno',
      });
    }
  };

  const getVinculos = () => {
    try {
      api.get(`grupos/vinculos/${id}`).then((resp) => {
        setVinculos(resp.data);
      });
    } catch (error) {
      console.log(error);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao buscar vinculos',
      });
    }
  };

  const ExcluirVinculo = (id: number) => {
    try {
      setExcluir(true);
      api.delete(`grupos/vinculo/${id}/aluno`).then(() => {
        getVinculos();
        setExcluir(false);
        addToast({
          type: 'success',
          title: 'Excluido com sucesso',
        });
      });
    } catch (error) {
      setExcluir(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir vincular',
      });
    }
  };

  const excluirAluno = () => {
    try {
      setLoading(true);
      api
        .delete(`alunos/${id}`)
        .then(() => {
          navigate(`/alunos`);
          addToast({
            type: 'success',
            title: 'Excluido com sucesso',
          });
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Ocorreu um error ao excluir aluno',
          });
        });
      setLoading(false);
    } catch (error) {
      setConfirmExcluir(false);
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir aluno',
      });
    }
  };

  const redefinirSenha = async () => {
    try {
      setLoading(true);
      await api.post(`account/reset-password-admin?id=${aluno.id}`).then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Senha redefinida!',
        });
      });
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Ocorreu um error ao excluir aluno',
      });
    }
  };

  return (
    <>
      {/* <Header /> */}
      <Container>
        <Content>
          <Schedule>
            <h1>Cliente</h1>
            {showForm && (
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{ id: id, professor: professor }}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <Section>
                  <strong>Dados</strong>
                  <InputGroup>
                    <Input name="nome" placeholder="Nome" />
                    <Input name="sobrenome" placeholder="Sobrenome" />
                  </InputGroup>
                  <InputGroup>
                    <InputDate name="nascimento" placeholder="Nascimento" subicon={FiCalendar} onauxfunction={handleCalcIdade} />
                    {/* <InputMask name="nascimento" placeholder="Nascimento" mask={'99/99/9999'} onChange={handleCalcIdade} /> */}
                    <Input name="idade" placeholder="Idade" disabled />
                  </InputGroup>
                  <InputGroup>
                    <Select
                      name="sexo"
                      id="sexo"
                      placeholder="Sexo"
                      options={[
                        { name: 'Feminino', value: '2' },
                        { name: 'Masculino', value: '1' },
                      ]}
                    />
                    {edit && (
                      <Select
                        name="estadoCivil"
                        id="estadocivil"
                        placeholder="Estado Civil"
                        options={[
                          { name: 'Solteiro', value: 1 },
                          { name: 'Casado', value: 2 },
                          { name: 'Viuvo', value: 3 },
                          { name: 'Divorciado', value: 4 },
                          { name: 'Não Declarado', value: 5 },
                        ]}
                      />
                    )}
                  </InputGroup>
                  <InputGroup>
                    {edit && <Select name="escolaridade" id="escolaridade" placeholder="Escolaridade" options={escolaridade} />}
                    <Input name="email" placeholder="E-mail" />
                  </InputGroup>
                  <InputGroup>
                    <InputMask name="celular" placeholder="Celular" mask={'(99) 99999-9999'} />
                    <InputMask name="cpf" placeholder="CPF" mask={'999.999.999-99'} />
                  </InputGroup>
                  {!edit && (
                    <InputGroup>
                      <Select name="grupo" placeholder="Grupo" id="grupo" options={grupos} />
                    </InputGroup>
                  )}
                  {edit && role !== 'Professor' && (
                    <InputGroup>
                      <Select name="professor" placeholder="Professor" id="professor" options={professores} />
                    </InputGroup>
                  )}
                </Section>
                {edit && (
                  <Section>
                    <strong>Endereço</strong>
                    <InputGroup>
                      <InputMask name="cep" placeholder="CEP" mask={'99999-999'} alwaysShowMask={true} onChange={getCEP} />
                      <Input name="endereco" placeholder="Endereço" />
                    </InputGroup>
                    <InputGroup>
                      <Select name="estado" id="estado" placeholder="Estado" options={estados} />
                      <Input name="cidade" placeholder="Cidade" />
                    </InputGroup>
                  </Section>
                )}
                {/* !aluno.objOk && */}
                {edit && (
                  <Section>
                    <strong>Questionários Iniciais</strong>
                    <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                      {questionarios.map((q: any, i: number) => (
                        <div key={q.id}>
                          <Checkbox name={`questionarios[${i}].checked`} value={q.codigo} label={q.nome} />
                          <div style={{ visibility: 'hidden' }}>
                            <Input name={`questionarios[${i}].id`} defaultValue={q.codigo} hidden />
                          </div>
                        </div>
                      ))}
                    </div>
                  </Section>
                )}
                <div style={{ visibility: 'hidden' }}>
                  <Input name="id" type="number" />
                  <Input name="professor" type="number" />
                </div>
                <Button loading={loading} type="submit">
                  Gravar
                </Button>
                {edit && (
                  <Section>
                    <strong>Acessos</strong>
                    <div style={{ display: 'flex', gap: 20, marginBottom: 20 }}>
                      <Option name="role" options={radioOptions} onChange={changeAcesso} />
                    </div>
                    <div>
                      {!aluno?.bloqueado && (new Date(aluno?.validade) > new Date() || aluno?.validade == null) && (
                        <BlockGroup>
                          <Button onClick={bloquear}>Bloquear</Button>
                        </BlockGroup>
                      )}
                      {(aluno?.bloqueado || new Date(aluno?.validade) < new Date()) && (
                        <>
                          <ValidadeGroup>
                            <InputDate name="validade" placeholder="Validade" subicon={FiCalendar} />
                            <div>
                              <Button onClick={desbloquear}>Liberar</Button>
                            </div>
                          </ValidadeGroup>
                          <BlockGroup style={{ marginTop: 10 }}>
                            <Button onClick={() => setConfirmExcluir(true)}>Excluir</Button>
                          </BlockGroup>
                        </>
                      )}
                      {role.toUpperCase() === 'ADMIN' && (
                        <div className="mt-2">
                          <ResetGroup>
                            <Button onClick={redefinirSenha}>Redefinir Senha</Button>
                          </ResetGroup>
                        </div>
                      )}
                    </div>
                  </Section>
                )}
                {edit && (
                  <Section>
                    <strong>Vinculos</strong>
                    <VinculoInput>
                      <Select name="grupoaluno" placeholder="Grupo" id="grupoaluno" options={grupos} />
                      <Button onClick={Vincular}>Confirmar</Button>
                    </VinculoInput>
                    <RowContainer>
                      {vinculos.map((v: any) => (
                        <Row key={v.id}>
                          <div>
                            <strong>{v.grupoNome}</strong>
                            <Button onClick={() => ExcluirVinculo(v.id)} loading={excluir} disabled={excluir}>
                              excluir
                            </Button>
                          </div>
                        </Row>
                      ))}
                    </RowContainer>
                  </Section>
                )}
                <Modal visible={modal} close={handleCloseModal}>
                  <p style={{ justifyContent: 'center', marginBottom: 20 }}>Marque os exames obrigatórios</p>
                  <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                    {questionarios.map((q: any, i: number) => (
                      <div key={q.id}>
                        <Checkbox name={`questionarios[${i}].checked`} value={q.id} label={q.nome} />
                        <div style={{ visibility: 'hidden' }}>
                          <Input name={`questionarios[${i}].id`} defaultValue={q.id} hidden />
                        </div>
                      </div>
                    ))}
                    <Button onClick={desbloquear}>Confirmar</Button>
                  </div>
                </Modal>
                <Modal visible={confirmExcluir} close={() => setConfirmExcluir(false)}>
                  <p style={{ justifyContent: 'center', marginBottom: 20 }}>EXCLUIR</p>
                  <div>
                    <p style={{ justifyContent: 'center' }}>Deseja realmente excluir o aluno?</p>
                    <p style={{ justifyContent: 'center' }}>Essa operação não pode ser desfeita</p>
                    <Button onClick={excluirAluno} loading={loading}>
                      Confirmar
                    </Button>
                  </div>
                </Modal>
              </Form>
            )}
          </Schedule>
        </Content>
      </Container>
    </>
  );
};

export default CadastroAluno;
