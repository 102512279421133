import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';

import Button from '../../components/Button';

import Input from '../../components/Input';
import Option from '../../components/Option';
import { useToast } from '../../hooks/toast';
import { Aluno } from '../../model/Aluno';
import { AntropometriaSimples } from '../../model/Antropometria';
import { Dobras } from '../../model/Dobras';
import { RadioOption } from '../../model/Inputs';
import api from '../../services/api';
import { calcularIdade, classificacaoGordura, classificacaoGorduraDeuremberg } from '../../utils/calcular';

import { Calculos, Container, Content, MassaCorporal, Perimetria, Protocolo, Protocolos, Schedule, Section, Title } from './styles';

const options: RadioOption[] = [
  { id: '1', value: 1, label: 'DDA' },
  { id: '2', value: 2, label: 'DEUREMBERG' },
  { id: '3', value: 3, label: 'WELTMAN' },
  { id: '4', value: 4, label: 'SLAUGHTER' },
  { id: '5', value: 5, label: 'POLLOCK' },
];
const ComposicaoCorporal: React.FC = () => {
  const { alunoid, id } = useParams();
  const navigate = useNavigate();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [aluno, setAluno] = useState<Aluno>({} as Aluno);
  const [antropometria, setAntropometria] = useState<AntropometriaSimples>({} as AntropometriaSimples);
  const [dobras, setDobras] = useState<Dobras>({} as Dobras);
  const [protocolo, setProtocolo] = useState(0);

  useEffect(() => {
    formRef.current?.setData({ aluno: Number(alunoid), id: Number(id) });
  }, [alunoid, id]);

  useEffect(() => {
    if (Number(id) === 0) {
      api
        .get('alunos/' + alunoid)
        .then((response) => {
          setAluno(response.data);
          const { data } = response;
          formRef.current?.setData(data);
          formRef.current?.setFieldValue('sexo', data.sexo === 1 ? 'Masculino' : 'Feminino');
          formRef.current?.setFieldValue('idade', calcularIdade(data.nascimento, new Date()));
        })
        .then(() => {
          api
            .get('antropometrias/aluno/' + alunoid)
            .then((response) => {
              setAntropometria(response.data);
              const { data } = response;
              delete data.id;
              formRef.current?.setData(data);
            })
            .catch((e) => {
              addToast({
                type: 'error',
                title: e.response.data.message,
              });
            });
        })
        .then(() => {
          api
            .get('dobras/aluno/' + alunoid)
            .then((response) => {
              setDobras(response.data);
              const { data } = response;
              delete data.id;
              formRef.current?.setData(data);
            })
            .catch((e) => {
              addToast({
                type: 'info',
                title: e.response.data.message,
              });
            });
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        });
    } else {
      api
        .get('alunos/' + alunoid)
        .then((response) => {
          setAluno(response.data);
        })
        .then(() => {
          api
            .get('antropometrias/aluno/' + alunoid)
            .then((response) => {
              setAntropometria(response.data);
              const { data } = response;
              delete data.id;
              formRef.current?.setData(data);
            })
            .catch((e) => {
              addToast({
                type: 'error',
                title: 'error',
              });
            });
        })
        .catch((e) => {
          addToast({
            type: 'error',
            title: 'error',
          });
        })
        .then(() => {
          api.get('composicoescorporais/' + id).then((response) => {
            const { data } = response;
            formRef.current?.setData(response.data);
            formRef.current?.setFieldValue('pernaDireita', data.panturrilha);
            const dto: AntropometriaSimples = {
              altura: data.altura,
              peso: data.peso,
              cintura: data.cintura,
              quadril: data.quadril,
              pescoco: data.pescoco,
              pernaDireita: data.panturrilha,
              protocolo: data.protocolo,
            };
            setAntropometria(dto);
            setProtocolo(data.protocolo);
          });
        });
    }
  }, [alunoid, id, addToast]);

  useEffect(() => {
    const calc1 = () => {
      const VAR1 = Math.log10(antropometria.altura);
      const VAR2 = Math.log10(antropometria.cintura - antropometria.pescoco);
      const VAR3 = Math.log10(antropometria.cintura + antropometria.quadril - antropometria.pescoco);
      if (aluno.sexo === 1) {
        const valor = 495 / (1.0324 - 0.19077 * VAR2 + 0.15456 * VAR1) - 450;
        if (isNaN(valor)) {
          return;
        }
        const classif = classificacaoGordura(aluno.sexo, calcularIdade(aluno.nascimento, new Date()), valor);
        formRef.current?.setFieldValue('dda.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('dda.classificacao', classif);
      } else {
        const valor = 495 / (1.29579 - 0.35004 * VAR3 + 0.221 * VAR1) - 450;
        if (isNaN(valor)) {
          return;
        }
        const classif = classificacaoGordura(aluno.sexo, calcularIdade(aluno.nascimento, new Date()), valor);
        formRef.current?.setFieldValue('dda.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('dda.classificacao', classif);
      }
    };

    const calc2 = () => {
      const imc = (antropometria.peso / (antropometria.altura * antropometria.altura)) * 10000;
      const idade = calcularIdade(aluno.nascimento, new Date());
      const sexo = aluno.sexo === 1 ? 1 : 0;
      const valor = 1.2 * imc + 0.23 * idade - 10.8 * sexo - 5.4;
      const classif = classificacaoGorduraDeuremberg(aluno.sexo, idade, valor);
      formRef.current?.setFieldValue('deurenberg.valor', valor.toFixed(2));
      formRef.current?.setFieldValue('deurenberg.classificacao', classif);
    };

    const calc3 = () => {
      const idade = calcularIdade(aluno.nascimento, new Date());
      const VAR1 = aluno.sexo === 1 ? 0.31457 : 0.11077;
      const VAR2 = aluno.sexo === 1 ? 0.10969 : 0.17666;
      const VAR3 = aluno.sexo === 1 ? 10.8336 : 51.03301;
      if (aluno.sexo === 1) {
        const valor = VAR1 * antropometria.cintura - VAR2 * antropometria.peso + VAR3;
        const classif = classificacaoGordura(aluno.sexo, idade, valor);
        formRef.current?.setFieldValue('weltman.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('weltman.classificacao', classif);
      } else {
        const valor = VAR1 * antropometria.cintura - VAR2 * antropometria.altura + 0.187 * antropometria.peso + VAR3;
        const classif = classificacaoGordura(aluno.sexo, idade, valor);
        formRef.current?.setFieldValue('weltman.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('weltman.classificacao', classif);
      }
    };

    const calc4 = () => {
      //slaughter
      const idade = calcularIdade(aluno.nascimento, new Date());
      if (aluno.sexo === 1) {
        const densidade = 0.735 * (antropometria.cintura + antropometria.pernaDireita) + 1;
        const valor = 495 / densidade - 450;
        const classif = classificacaoGordura(aluno.sexo, idade, valor);
        formRef.current?.setFieldValue('slaughter.densidade', densidade.toFixed(2));
        formRef.current?.setFieldValue('slaughter.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('slaughter.classificacao', classif);
      } else {
        const densidade = 0.61 * (antropometria.cintura + antropometria.pernaDireita) + 5.1;
        const valor = 501 / densidade - 457;
        const classif = classificacaoGordura(aluno.sexo, idade, valor);
        formRef.current?.setFieldValue('slaughter.densidade', densidade.toFixed(2));
        formRef.current?.setFieldValue('slaughter.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('slaughter.classificacao', classif);
      }
    };

    const calc5 = () => {
      //pollock
      const idade = calcularIdade(aluno.nascimento, new Date());
      if (aluno.sexo === 1) {
        // const densidade = 0.735 * (antropometria.cintura + antropometria.pernaDireita) + 1;
        const densidade =
          1.10938 -
          0.0008267 * (dobras.peitoral + dobras.abdominal + dobras.coxa) +
          0.0000016 * ((dobras.peitoral + dobras.abdominal + dobras.coxa) * (dobras.peitoral + dobras.abdominal + dobras.coxa)) -
          0.0002574 * idade;
        const valor = 495 / densidade - 450;
        const classif = classificacaoGordura(aluno.sexo, idade, valor);
        formRef.current?.setFieldValue('pollock.densidade', densidade.toFixed(2));
        formRef.current?.setFieldValue('pollock.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('pollock.classificacao', classif);
      } else {
        // const densidade = 0.61 * (antropometria.cintura + antropometria.pernaDireita) + 5.1;
        const densidade =
          1.0994921 -
          0.0009929 * (dobras.tricipital + dobras.suprailiaca + dobras.coxa) +
          0.0000023 * ((dobras.tricipital + dobras.suprailiaca + dobras.coxa) * (dobras.tricipital + dobras.suprailiaca + dobras.coxa)) -
          0.0001392 * idade;
        const valor = 501 / densidade - 457;
        const classif = classificacaoGordura(aluno.sexo, idade, valor);
        formRef.current?.setFieldValue('pollock.densidade', densidade.toFixed(2));
        formRef.current?.setFieldValue('pollock.valor', valor.toFixed(2));
        formRef.current?.setFieldValue('pollock.classificacao', classif);
      }
    };

    const calc6 = () => {
      const peso = antropometria.peso;
      const dda = formRef.current?.getFieldValue('dda.valor');
      const deurenberg = formRef.current?.getFieldValue('deurenberg.valor');
      const weltman = formRef.current?.getFieldValue('weltman.valor');
      const slaughter = formRef.current?.getFieldValue('slaughter.valor');
      const pollock = formRef.current?.getFieldValue('pollock.valor');

      const _lmin = aluno.sexo === 1 ? 0.84 : 0.9;
      const _lmax = aluno.sexo === 1 ? 0.75 : 0.82;
      const _gordura = aluno.sexo === 1 ? 16 : 24;
      const massagorda1 = (peso * dda) / 100;
      const massagorda2 = (peso * deurenberg) / 100;
      const massagorda3 = (peso * weltman) / 100;
      const massagorda4 = (peso * slaughter) / 100;
      const massagorda5 = (peso * pollock) / 100;

      const massamagra1 = peso - massagorda1;
      const massamagra2 = peso - massagorda2;
      const massamagra3 = peso - massagorda3;
      const massamagra4 = peso - massagorda4;
      const massamagra5 = peso - massagorda5;

      const lminres1 = massamagra1 / _lmin;
      const lminres2 = massamagra2 / _lmin;
      const lminres3 = massamagra3 / _lmin;
      const lminres4 = massamagra4 / _lmin;
      const lminres5 = massamagra5 / _lmin;

      const lmaxres1 = lminres1 / _lmax;
      const lmaxres2 = lminres2 / _lmax;
      const lmaxres3 = lminres3 / _lmax;
      const lmaxres4 = lminres4 / _lmax;
      const lmaxres5 = lminres5 / _lmax;

      const gordura1 = (dda - _gordura) / 100;
      const gordura2 = (deurenberg - _gordura) / 100;
      const gordura3 = (weltman - _gordura) / 100;
      const gordura4 = (slaughter - _gordura) / 100;
      const gordura5 = (pollock - _gordura) / 100;

      const pesoideal1 = massamagra1 + (massagorda1 - massagorda1 * gordura1);
      const pesoideal2 = massamagra2 + (massagorda2 - massagorda2 * gordura2);
      const pesoideal3 = massamagra3 + (massagorda3 - massagorda3 * gordura3);
      const pesoideal4 = massamagra4 + (massagorda4 - massagorda4 * gordura4);
      const pesoideal5 = massamagra5 + (massagorda5 - massagorda5 * gordura5);

      //
      formRef.current?.setFieldValue('dda.massagorda', massagorda1.toFixed(2));
      formRef.current?.setFieldValue('deurenberg.massagorda', massagorda2.toFixed(2));
      formRef.current?.setFieldValue('weltman.massagorda', massagorda3.toFixed(2));
      formRef.current?.setFieldValue('slaughter.massagorda', massagorda4.toFixed(2));
      formRef.current?.setFieldValue('pollock.massagorda', massagorda5.toFixed(2));
      //
      formRef.current?.setFieldValue('dda.massamagra', massamagra1.toFixed(2));
      formRef.current?.setFieldValue('deurenberg.massamagra', massamagra2.toFixed(2));
      formRef.current?.setFieldValue('weltman.massamagra', massamagra3.toFixed(2));
      formRef.current?.setFieldValue('slaughter.massamagra', massamagra4.toFixed(2));
      formRef.current?.setFieldValue('pollock.massamagra', massamagra5.toFixed(2));
      //
      formRef.current?.setFieldValue('dda.lminres', lminres1.toFixed(2));
      formRef.current?.setFieldValue('deurenberg.lminres', lminres2.toFixed(2));
      formRef.current?.setFieldValue('weltman.lminres', lminres3.toFixed(2));
      formRef.current?.setFieldValue('slaughter.lminres', lminres4.toFixed(2));
      formRef.current?.setFieldValue('pollock.lminres', lminres5.toFixed(2));
      //
      formRef.current?.setFieldValue('dda.lmaxres', lmaxres1.toFixed(2));
      formRef.current?.setFieldValue('deurenberg.lmaxres', lmaxres2.toFixed(2));
      formRef.current?.setFieldValue('weltman.lmaxres', lmaxres3.toFixed(2));
      formRef.current?.setFieldValue('slaughter.lmaxres', lmaxres4.toFixed(2));
      formRef.current?.setFieldValue('pollock.lmaxres', lmaxres5.toFixed(2));
      //
      formRef.current?.setFieldValue('dda.gorduraexcesso', gordura1.toFixed(2));
      formRef.current?.setFieldValue('deurenberg.gorduraexcesso', gordura2.toFixed(2));
      formRef.current?.setFieldValue('weltman.gorduraexcesso', gordura3.toFixed(2));
      formRef.current?.setFieldValue('slaughter.gorduraexcesso', gordura4.toFixed(2));
      formRef.current?.setFieldValue('pollock.gorduraexcesso', gordura5.toFixed(2));
      //
      formRef.current?.setFieldValue('dda.pesoideal', pesoideal1.toFixed(2));
      formRef.current?.setFieldValue('deurenberg.pesoideal', pesoideal2.toFixed(2));
      formRef.current?.setFieldValue('weltman.pesoideal', pesoideal3.toFixed(2));
      formRef.current?.setFieldValue('slaughter.pesoideal', pesoideal4.toFixed(2));
      formRef.current?.setFieldValue('pollock.pesoideal', pesoideal5.toFixed(2));
      //
    };

    const calc7 = () => {
      const idade = calcularIdade(aluno.nascimento, new Date());
      const imc = (antropometria.peso / (antropometria.altura * antropometria.altura)) * 10000;
      const var1 = aluno.sexo === 1 ? 10.8 : 0;
      const massageral = 1.2 * imc - (var1 + 0.23 * idade - 5.4);
      const massagorda = (massageral * idade) / 100;
      const massamagra = antropometria.peso - massagorda;
      const imcnormal = imc - 24.9;
      formRef.current?.setFieldValue('imc.valor', imc.toFixed(2));
      formRef.current?.setFieldValue('imc.massageral', massageral.toFixed(2));
      formRef.current?.setFieldValue('imc.massagorda', massagorda.toFixed(2));
      formRef.current?.setFieldValue('imc.massamagra', massamagra.toFixed(2));
      formRef.current?.setFieldValue('imc.limitenormal', imcnormal.toFixed(2));

      let classif = '';

      if (imc < 18.5) {
        classif = 'Peso abaixo do ideal';
      } else if (imc > 18.5 && imc < 25) {
        classif = 'Peso normal';
      } else if (imc >= 25 && imc < 30) {
        classif = 'Sobrepeso';
      } else if (imc >= 30 && imc < 35) {
        classif = 'Obesidade I';
      } else if (imc >= 35 && imc < 40) {
        classif = 'Obesidade II';
      } else if (imc > 40) {
        classif = 'Obesidade III';
      } else {
        classif = '';
      }
      formRef.current?.setFieldValue('imc.classificacao', classif);
    };

    calc1();
    calc2();
    calc3();
    calc4();
    calc5();
    calc6();
    calc7();
    return () => {};
  }, [antropometria, alunoid, aluno, protocolo, dobras.abdominal, dobras.coxa, dobras.peitoral, dobras.suprailiaca, dobras.tricipital]);

  const selectProtocolo = () => {
    const valor = formRef.current?.getFieldValue('protocolo');
    setProtocolo(Number(valor));
  };

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});
        await schema.validate(data, {
          abortEarly: false,
        });
        setLoading(true);
        data.sexo = data.sexo === 'Masculino' ? '1' : '2';
        const _data = _.mapValues(data, (v) => (_.isEmpty(v) ? null : v));

        if (Number(id) === 0) {
          await api.post('composicoescorporais', _data);
        } else {
          await api.put('composicoescorporais/' + id, _data);
        }
        setLoading(false);

        addToast({
          type: 'success',
          title: 'Gravado com sucesso!',
        });
        navigate(`/cliente/${alunoid}/questionarios`);
      } catch (error) {
        console.log(error);
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Verifique o formulário',
        });
      }
    },
    [alunoid, id, addToast, navigate]
  );

  return (
    <Container>
      {/* <Header /> */}
      <Content>
        <Schedule>
          <Title>
            <h1>Composição Corporal</h1>
            <Link to={`/cliente/${alunoid}/questionarios`}>
              <FiChevronLeft size={20} />
              <span>Voltar</span>
            </Link>
          </Title>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{ aluno: alunoid }}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <Section>
              <strong>Cálculo % de gordura - Protocolos</strong>
              <Protocolos>
                <div>
                  <div>Sexo</div>
                  <div>
                    <Input name="sexo" readOnly />
                  </div>
                </div>
                <div>
                  <div>Altura</div>
                  <div>
                    <Input name="altura" readOnly />
                  </div>
                </div>
                <div>
                  <div>Peso</div>
                  <div>
                    <Input name="peso" readOnly />
                  </div>
                </div>
                <div>
                  <div>Idade</div>
                  <div>
                    <Input name="idade" readOnly />
                  </div>
                </div>
                <div>
                  <div>Cintura</div>
                  <div>
                    <Input name="cintura" readOnly />
                  </div>
                </div>
                <div>
                  <div>Quadril</div>
                  <div>
                    <Input name="quadril" readOnly />
                  </div>
                </div>
                <div>
                  <div>Pescoço</div>
                  <div>
                    <Input name="pescoco" readOnly />
                  </div>
                </div>
                <div>
                  <div>Panturrilha</div>
                  <div>
                    <Input name="pernaDireita" readOnly />
                  </div>
                </div>
              </Protocolos>
            </Section>
            <Section>
              <strong>Selecionar o protocolo que será utilizado</strong>
              <Protocolo>
                <Option name="protocolo" options={options} onChange={selectProtocolo} />
              </Protocolo>
            </Section>
            <Section>
              {protocolo === 1 && (
                <>
                  <strong>DEPARTAMENTO DE DEFESA AMERICANO - só perimetria - Homens e mulheres adultos</strong>
                  <Perimetria>
                    <div>
                      <div>Índices</div>
                      <div>% gordura</div>
                    </div>
                    <div>
                      <div>Valor</div>
                      <div>
                        <Input name="dda.valor" readOnly />
                      </div>
                    </div>
                    <div>
                      <div>Classificação</div>
                      <div>
                        <Input name="dda.classificacao" readOnly />
                      </div>
                    </div>
                  </Perimetria>
                </>
              )}
              {protocolo === 2 && (
                <>
                  <strong>DEUREMBERG – cálculo por IMC - Homens e mulheres adultos</strong>
                  <Perimetria>
                    <div>
                      <div>Índices</div>
                      <div>% gordura</div>
                    </div>
                    <div>
                      <div>Valor</div>
                      <div>
                        <Input name="deurenberg.valor" readOnly />
                      </div>
                    </div>
                    <div>
                      <div>Classificação</div>
                      <div>
                        <Input name="deurenberg.classificacao" readOnly />
                      </div>
                    </div>
                  </Perimetria>
                </>
              )}
              {protocolo === 3 && (
                <>
                  <strong>WELTMAN – só perimetria cintura - Mulheres obesas (20 a 60 anos) Homens obesos (24 a 68 anos) </strong>
                  <Perimetria>
                    <div>
                      <div>Índices</div>
                      <div>% gordura</div>
                    </div>
                    <div>
                      <div>Valor</div>
                      <div>
                        <Input name="weltman.valor" readOnly />
                      </div>
                    </div>
                    <div>
                      <div>Classificação</div>
                      <div>
                        <Input name="weltman.classificacao" readOnly />
                      </div>
                    </div>
                  </Perimetria>
                </>
              )}
              {protocolo === 4 && (
                <>
                  <strong>slaughter(6 A 17 ANOS) – crianças e jovens</strong>
                  <Perimetria>
                    <div>
                      <div>Índices</div>
                      <div>Densidade</div>
                      <div>% gordura</div>
                    </div>
                    <div>
                      <div>Valor</div>
                      <div style={{ marginBottom: 8 }}>
                        <Input name="slaughter.densidade" readOnly />
                      </div>
                      <div>
                        <Input name="slaughter.valor" readOnly />
                      </div>
                    </div>
                    <div>
                      <div>Classificação</div>
                      <div style={{ height: '100%' }}></div>
                      <div>
                        <Input name="slaughter.classificacao" readOnly />
                      </div>
                    </div>
                  </Perimetria>
                </>
              )}
              {protocolo === 5 && (
                <>
                  <strong>POLLOCK 3 dobras(18 a 61 anos)</strong>
                  <Perimetria>
                    <div>
                      <div>Índices</div>
                      <div>Densidade</div>
                      <div>% gordura</div>
                    </div>
                    <div>
                      <div>Valor</div>
                      <div style={{ marginBottom: 8 }}>
                        <Input name="pollock.densidade" readOnly />
                      </div>
                      <div>
                        <Input name="pollock.valor" readOnly />
                      </div>
                    </div>
                    <div>
                      <div>Classificação</div>
                      <div style={{ height: '100%' }}></div>
                      <div>
                        <Input name="pollock.classificacao" readOnly />
                      </div>
                    </div>
                  </Perimetria>
                </>
              )}
            </Section>
            <Section>
              <Calculos>
                <div>
                  <div>CÁLCULOS</div>
                  {protocolo === 1 && <div>DDA</div>}
                  {protocolo === 2 && <div>DEURENBERG</div>}
                  {protocolo === 3 && <div>WELTMAN</div>}
                  {protocolo === 4 && <div>SLAUGHTER</div>}
                  {protocolo === 5 && <div>POLLOCK</div>}
                </div>
                <div>
                  <div>Massa Gorda</div>
                  {protocolo === 1 && (
                    <div>
                      <Input name="dda.massagorda" readOnly />
                    </div>
                  )}
                  {protocolo === 2 && (
                    <div>
                      <Input name="deurenberg.massagorda" readOnly />
                    </div>
                  )}
                  {protocolo === 3 && (
                    <div>
                      <Input name="weltman.massagorda" readOnly />
                    </div>
                  )}
                  {protocolo === 4 && (
                    <div>
                      <Input name="slaughter.massagorda" readOnly />
                    </div>
                  )}
                  {protocolo === 5 && (
                    <div>
                      <Input name="pollock.massagorda" readOnly />
                    </div>
                  )}
                </div>
                <div>
                  <div>Massa Magra</div>
                  {protocolo === 1 && (
                    <div>
                      <Input name="dda.massamagra" readOnly />
                    </div>
                  )}
                  {protocolo === 2 && (
                    <div>
                      <Input name="deurenberg.massamagra" readOnly />
                    </div>
                  )}
                  {protocolo === 3 && (
                    <div>
                      <Input name="weltman.massamagra" readOnly />
                    </div>
                  )}
                  {protocolo === 4 && (
                    <div>
                      <Input name="slaughter.massamagra" readOnly />
                    </div>
                  )}
                  {protocolo === 5 && (
                    <div>
                      <Input name="pollock.massamagra" readOnly />
                    </div>
                  )}
                </div>
                <div>
                  <div>LminRes</div>
                  {protocolo === 1 && (
                    <div>
                      <Input name="dda.lminres" readOnly />
                    </div>
                  )}
                  {protocolo === 2 && (
                    <div>
                      <Input name="deurenberg.lminres" readOnly />
                    </div>
                  )}
                  {protocolo === 3 && (
                    <div>
                      <Input name="weltman.lminres" readOnly />
                    </div>
                  )}
                  {protocolo === 4 && (
                    <div>
                      <Input name="slaughter.lminres" readOnly />
                    </div>
                  )}
                  {protocolo === 5 && (
                    <div>
                      <Input name="pollock.lminres" readOnly />
                    </div>
                  )}
                </div>
                <div>
                  <div>LmáxRes</div>
                  {protocolo === 1 && (
                    <div>
                      <Input name="dda.lmaxres" readOnly />
                    </div>
                  )}
                  {protocolo === 2 && (
                    <div>
                      <Input name="deurenberg.lmaxres" readOnly />
                    </div>
                  )}
                  {protocolo === 3 && (
                    <div>
                      <Input name="weltman.lmaxres" readOnly />
                    </div>
                  )}
                  {protocolo === 4 && (
                    <div>
                      <Input name="slaughter.lmaxres" readOnly />
                    </div>
                  )}
                  {protocolo === 5 && (
                    <div>
                      <Input name="pollock.lmaxres" readOnly />
                    </div>
                  )}
                </div>
                <div>
                  <div>% Gordura em excesso</div>
                  {protocolo === 1 && (
                    <div>
                      <Input name="dda.gorduraexcesso" readOnly />
                    </div>
                  )}
                  {protocolo === 2 && (
                    <div>
                      <Input name="deurenberg.gorduraexcesso" readOnly />
                    </div>
                  )}
                  {protocolo === 3 && (
                    <div>
                      <Input name="weltman.gorduraexcesso" readOnly />
                    </div>
                  )}
                  {protocolo === 4 && (
                    <div>
                      <Input name="slaughter.gorduraexcesso" readOnly />
                    </div>
                  )}
                  {protocolo === 5 && (
                    <div>
                      <Input name="pollock.gorduraexcesso" readOnly />
                    </div>
                  )}
                </div>
                <div>
                  <div>Peso ideal</div>
                  {protocolo === 1 && (
                    <div>
                      <Input name="dda.pesoideal" readOnly />
                    </div>
                  )}
                  {protocolo === 2 && (
                    <div>
                      <Input name="deurenberg.pesoideal" readOnly />
                    </div>
                  )}
                  {protocolo === 3 && (
                    <div>
                      <Input name="weltman.pesoideal" readOnly />
                    </div>
                  )}
                  {protocolo === 4 && (
                    <div>
                      <Input name="slaughter.pesoideal" readOnly />
                    </div>
                  )}
                  {protocolo === 5 && (
                    <div>
                      <Input name="pollock.pesoideal" readOnly />
                    </div>
                  )}
                </div>
              </Calculos>
            </Section>
            <Section>
              <strong>Cálculo Índice de Massa Corporal - IMC</strong>
              <MassaCorporal>
                <div>
                  <div>Índice de Massa Corporal</div>
                  <div>Massa Geral</div>
                  <div>Massa Gorda</div>
                  <div>Massa Magra</div>
                  <div>IMC Limite Normal</div>
                </div>
                <div>
                  <div>
                    <Input name="imc.valor" readOnly />
                  </div>
                  <div>
                    <Input name="imc.massageral" readOnly />
                  </div>
                  <div>
                    <Input name="imc.massagorda" readOnly />
                  </div>
                  <div>
                    <Input name="imc.massamagra" readOnly />
                  </div>
                  <div>
                    <Input name="imc.limitenormal" readOnly />
                  </div>
                </div>
                <div>
                  <div>
                    <Input name="imc.classificacao" readOnly />
                  </div>
                </div>
              </MassaCorporal>
            </Section>
            <Button loading={loading} disabled={loading} type="submit">
              Gravar
            </Button>
            <div style={{ visibility: 'hidden' }}>
              <Input name="aluno" type="number" />
              <Input name="id" type="number" />
            </div>
          </Form>
        </Schedule>
      </Content>
    </Container>
  );
};

export default ComposicaoCorporal;
