import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import {
  RadarCalculo2,
  RadarCalculo3,
  RadarEmocionalDepara,
  RadarFisicoDepara,
  RadarRelacionamentoDepara,
  colorCardWr1,
  colorCardWr7,
  colorCardWr8,
} from './functions';
import doctor from '../../assets/icons/doctor.svg';
import psychologist from '../../assets/icons/psychologist.svg';
import dentist from '../../assets/icons/dentist.svg';
import nutritionist from '../../assets/icons/nutritionist.svg';
import medicine from '../../assets/icons/medicine.svg';
import healthInsurance from '../../assets/icons/health-insurance.svg';
import healthCondition from '../../assets/icons/health-condition.svg';
import dna from '../../assets/icons/dna.svg';
import diabetes from '../../assets/icons/diabetes.svg';
import blood from '../../assets/icons/blood-pressure.svg';
import obesity from '../../assets/icons/obesity.svg';
import lung from '../../assets/icons/lung.svg';
import knee from '../../assets/icons/knee-joint.svg';
import spine from '../../assets/icons/spine.svg';
import tendonitis from '../../assets/icons/tendonitis.svg';
import sadness from '../../assets/icons/sadness.svg';
import anxiety from '../../assets/icons/anxiety.svg';
import exercise from '../../assets/icons/exercise.svg';
import meal from '../../assets/icons/meal.svg';
import smoking from '../../assets/icons/smoking.svg';
import alcohol from '../../assets/icons/alcohol.svg';
import sleeping from '../../assets/icons/sleeping.svg';
import emotional from '../../assets/icons/emotional.svg';
import positivefeeling from '../../assets/icons/positivefeeling.svg';
import religion from '../../assets/icons/religion.svg';
import thinking from '../../assets/icons/thinking.svg';
import selfimage from '../../assets/icons/selfimage.svg';
import selfesteem from '../../assets/icons/selfesteem.svg';

// import { Container } from './styles';

interface ResumoProps {
  aluno: number;
}

const Resumo: React.FC<ResumoProps> = ({ aluno }) => {
  const { addToast } = useToast();
  const [dados, setDados] = useState<any>();
  useEffect(() => {
    api
      .get('dashboard/resumo?aluno=' + aluno)
      .then((response) => {
        setDados(response.data);
      })
      .catch((e) => {
        addToast({
          type: 'error',
          title: 'error',
        });
      });
  }, [aluno, addToast]);

  return (
    <>
      {dados && (
        <div>
          <div className="flex justify-between w-full">
            <div className="border-2 border-t-black border-x-black w-10/12">
              Nome: {dados.nome} {dados.sobrenome}
            </div>
            <div className="pl-1 border-2 border-t-black border-r-black w-2/12">Idade: {dados.idade}</div>
          </div>
          {/* <div className="border-2 border-t-black border-x-black">Objetivo:</div> */}
          {/* <div className="border-2 border-t-black border-x-black">Meta:</div> */}
          <div className="flex justify-between">
            <div className="border-2 border-t-black border-x-black w-1/3">Peso: {dados.peso} kg</div>
            <div className="border-2 border-t-black border-r-black w-1/3">Imc: {dados.imc}</div>
            <div className="border-2 border-t-black border-r-black w-1/3">Classificação: {dados.imcClassificacao}</div>
          </div>
          <div className="flex justify-between">
            <div className="border-2 border-t-black border-x-black w-1/2">% Gordura: {dados.gordura?.toFixed(2)}</div>
            <div className="border-2 border-t-black border-r-black w-1/2">Classificação: {dados.gorduraClassificacao}</div>
          </div>
          {dados.temRcq && (
            <div className="flex justify-between">
              <div className="border-2 border-t-black border-x-black w-1/5">RCQ: {dados.rcq}</div>
              <div className="border-2 border-t-black border-r-black w-1/5">Classificação: {dados.rcqClassificacao}</div>
              <div className="border-2 border-t-black border-r-black w-1/5">Cintura: {dados.cintura}</div>
              <div className="border-2 border-t-black border-r-black w-1/5">Classificação: {dados.cinturaClassificacao}</div>
              <div className="border-2 border-t-black border-r-black w-1/5">Quadril: {dados.quadril}</div>
            </div>
          )}
          <div className="flex justify-between">
            <div className="border-2 border-y-black border-x-black w-1/3">
              Dimensão Fisica:{' '}
              {RadarFisicoDepara(dados.wr01) + RadarFisicoDepara(dados.wr03) === 0 && <small className="text-default">Inativo</small>}
              {RadarFisicoDepara(dados.wr01) +
                RadarFisicoDepara(dados.wr02) +
                RadarFisicoDepara(dados.wr03) +
                RadarFisicoDepara(dados.wr04) <
                7 &&
                RadarFisicoDepara(dados.wr01) + RadarFisicoDepara(dados.wr03) !== 0 && (
                  <small className="text-default">Parcialmente Ativo</small>
                )}
              {RadarFisicoDepara(dados.wr01) +
                RadarFisicoDepara(dados.wr02) +
                RadarFisicoDepara(dados.wr03) +
                RadarFisicoDepara(dados.wr04) >
                6 && <small className="text-default">Ativo</small>}
            </div>
            <div className="border-2 border-y-black border-r-black w-1/3">
              Dimensão Social:{' '}
              {RadarCalculo2(
                RadarRelacionamentoDepara(dados.wr11),
                RadarRelacionamentoDepara(dados.wr12),
                RadarRelacionamentoDepara(dados.wr13),
                RadarRelacionamentoDepara(dados.wr14)
              )}
            </div>
            <div className="border-2 border-y-black border-r-black w-1/3">
              Dimensão Emocional:{' '}
              {RadarCalculo3(
                RadarEmocionalDepara(dados.wr15),
                RadarEmocionalDepara(dados.wr16),
                RadarEmocionalDepara(dados.wr17),
                RadarEmocionalDepara(dados.wr18),
                RadarEmocionalDepara(dados.wr19),
                RadarEmocionalDepara(dados.wr20)
              )}
            </div>
          </div>
          {dados.temAnamnese && (
            <div>
              <h1>Anamnese</h1>
              <div className="flex flex-wrap">
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini01 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={doctor} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini01 === 0 && 'não realiza acompanhamento / tratamento médico'}
                      {dados.ini01 === 1 && `realiza acompanhamento / tratamento médico na frequencia de ${dados.ini02} ano`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini03 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={psychologist} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini03 === 0 && 'não realiza acompanhamento / tratamento psicológico / psiquiátrico'}
                      {dados.ini03 === 1 &&
                        `realiza acompanhamento / tratamento psicológico / psiquiátrico para ${dados.ini04} na frequencia de ${dados.ini05} ao ano`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini06 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={dentist} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini06 === 0 && 'não realiza acompanhamento / tratamento odontológico'}
                      {dados.ini06 === 1 && `realiza acompanhamento / tratamento odontológico na frequencia de ${dados.ini07} ao ano`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini08 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={nutritionist} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini08 === 0 && 'não realiza acompanhamento / tratamento nutricional'}
                      {dados.ini08 === 1 && `realiza acompanhamento / tratamento nutricional na frequencia de ${dados.ini09} ao ano`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini10 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={medicine} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini10 === 0 && 'não toma nenhum tipo de medicamento'}
                      {dados.ini10 === 1 && `toma o medicamento por indicação ${dados.ini11}`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: '#fff' }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={healthInsurance} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini12 === 0 && `Não possui plano de Saúde`}
                      {dados.ini12 === 1 && `Possui Plano de Saúde ${dados.ini13}`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini14 === 'Bom' || dados.ini14 === 'Muito Bom' ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={healthCondition} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">{`Classifica sua condição de saúde como ${dados.ini14}`}</div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini15 === 'Não' ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={dna} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini15 === 'Não' ? 'Não possui risco hereditário' : `Possui risco hereditário em ${dados.ini15}`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini16 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={diabetes} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini16 === 0 && 'Nenhum médico disse que tenha ou já teve diabetes.'}
                      {dados.ini16 === 1 && `Já foi ou está diagnosticado com diabetes.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini17 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={blood} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini17 === 0 && 'Nenhum médico disse que tenha ou já teve hipertensão arterial.'}
                      {dados.ini17 === 1 && `Já foi ou está diagnosticado com hipertensão arterial.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini18 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={obesity} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini18 === 0 && 'Nenhum médico disse que tenha ou já teve obesidade.'}
                      {dados.ini18 === 1 && `Já foi ou está diagnosticado com obesidade.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini19 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={lung} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini19 === 0 && 'Nenhum médico disse que tenha ou já teve doença respiratória.'}
                      {dados.ini19 === 1 && `Já foi ou está diagnosticado com doença respiratória.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini20 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={knee} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini20 === 0 && 'Nenhum médico disse que tenha ou já teve doença articular.'}
                      {dados.ini20 === 1 && `Já foi ou está diagnosticado com doença articular.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini21 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={spine} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini21 === 0 && 'Nenhum médico disse que tenha ou já teve problema de coluna (costas).'}
                      {dados.ini21 === 1 && `Já foi ou está diagnosticado com problema de coluna (costas).`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini22 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={tendonitis} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini22 === 0 && 'Nenhum médico disse que tenha ou já teve tendinite ou LER.'}
                      {dados.ini22 === 1 && `Já foi ou está diagnosticado com tendinite ou LER.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini23 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={sadness} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini23 === 0 && 'Nenhum médico disse que tenha ou já teve Depressão.'}
                      {dados.ini23 === 1 && `Já foi ou está diagnosticado com Depressão.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.ini24 === 0 ? 'greenyellow' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={anxiety} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.ini24 === 0 && 'Nenhum médico disse que tenha ou já teve transtorno de ansiedade.'}
                      {dados.ini24 === 1 && `Já foi ou está diagnosticado com transtorno de ansiedade.`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {dados.temRadar && (
            <div>
              <h1>Estilo de Vida</h1>
              <div className="flex flex-wrap">
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr01) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={exercise} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wR01 === 'Nenhum'
                        ? 'Não CAMINHOU na última semana'
                        : `CAMINHOU ${dados.wR01} na última semana ${dados.wR02} contínuos.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr03) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={exercise} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr03 === 'Não'
                        ? 'NÃO PRATICOU, regularmente, algum tipo de atividade física'
                        : `PRATICOU ${dados.wr03} ${dados.wr04} contínuos.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr05) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={meal} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr05 === 'Nunca' ? 'NÃO Come verduras e legumes durante a semana.' : `Come verduras e legumes ${dados.wr05}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr06) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={meal} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr06 === 'Nunca'
                        ? 'NÃO Come frutas ou toma suco natural durante a semana.'
                        : `Come frutas ou toma suco natural ${dados.wr06}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr7(dados.wr07) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={meal} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr07 === 'Nunca'
                        ? 'NÃO Toma refrigerantes ou sucos artificias durante a semana.'
                        : `Toma refrigerantes ou sucos artificias ${dados.wr07}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr8(dados.wr08) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={smoking} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr08 === 'Nunca fumei' ? 'Nunca fumou.' : `${dados.wr08}.`}
                    </div>
                  </div>
                </div>
                <div
                  className="flex break-words rounded-md h-56 w-44 m-4"
                  style={{ background: dados.wr09 === 'Não' ? 'lawngreen' : 'red' }}
                >
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={alcohol} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr09 === 'Não'
                        ? 'NÃO TOMOU, nos últimos 30 dias, 5+ doses de bebidas alcoólicas numa mesma ocasião'
                        : 'TOMOU, nos últimos 30 dias, 5+ doses de bebidas alcoólicas numa mesma ocasião'}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr10) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={sleeping} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">{`Considera que DORME bem ${dados.wr10}.`}</div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr11) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={emotional} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {`Classifica a qualidade dos seus relacionamentos que mantém com outras pessoas como ${dados.wr11}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr12) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={emotional} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {`Dedica seu tempo voluntariamente a projetos social ${dados.wr12}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr13) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={emotional} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {`Classifica seu nível pessoal de integração/adaptação ao ambiente como ${dados.wr13}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr14) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={emotional} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {`Tem achado que está sendo útil na vida de alguém ${dados.wr14}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr15) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={positivefeeling} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr15 === 'Nada' ? 'NÃO aproveita a vida.' : `Aproveita a vida ${dados.wr15}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr16) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={religion} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr16 === 'Nada' ? 'Acha que a vida não tem sentido.' : `Acha que a vida tem ${dados.wr16}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr17) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={thinking} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr17 === 'Nada' ? 'Não consegue se concentrar.' : `Consegue se concentrar ${dados.wr17}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr18) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={selfimage} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr18 === 'Nada'
                        ? 'Não é capaz de aceitar sua aparência física.'
                        : `É capaz de aceitar sua aparência física ${dados.wr18}.`}
                    </div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr19) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={selfesteem} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">{`Está ${dados.wr19} consigo mesmo.`}</div>
                  </div>
                </div>
                <div className="flex break-words rounded-md h-56 w-44 m-4" style={{ background: colorCardWr1(dados.wr20) }}>
                  <div className="flex-auto p-4">
                    <a className="block m-auto h-16 w-16">
                      <img className="w-full" alt="..." src={exercise} />
                    </a>
                    <div className="text-center mt-5 h-12 text-black">
                      {dados.wr20 === 'Nunca' ? 'Nunca tem sentimentos positivos.' : `Tem sentimentos positivos ${dados.wr20}.`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Resumo;
